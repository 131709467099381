import ApiService from './ApiService'

export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: '/api/v1/auth/signin',
        method: 'post',
        data,
    })
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: '/api/v1/auth/signup',
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/api/v1/auth/logout',
        method: 'post',
        data,
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/api/v1/auth/reset',
        method: 'post',
        data,
    })
}
export async function apiResendOtp(data) {
    return ApiService.fetchData({
        url: '/api/v1/auth/resendOTP',
        method: 'post',
        data,
    })
}
export async function verifyOtp(data) {
    return ApiService.fetchData({
        url: '/api/v1/auth/verify',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/api/v1/reset-password',
        method: 'post',
        data,
    })
}
export async function apiChangePassword(data) {
    return ApiService.fetchData({
        url: '/api/v1/auth/change',
        method: 'post',
        data,
    })
}

export async function apiVerify2fa(data) {
    return ApiService.fetchData({
        url: '/gateway/api/v1/authenticator/verify',
        method: 'post',
        data,
    })
}
